<template>
    <div class="container-content">
      <TopHeader
        :activePage="page"
      />
      <div class="main-content">
        <div class="container-highlight">
            <div v-if="dataHighlightContentVideo.length != 0" class="card-highlight">
                <iframe width="560" height="315" :src="dataHighlightContentVideo.embed_video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <div class="box-information">
                    <div class="top-information">
                        <div class="title"><p>{{ dataHighlightContentVideo.video_title }}</p></div>
                        <div class="description"><p>{{ dataHighlightContentVideo.video_description }}</p></div>
                    </div>
                    <div class="bottom-information">
                        <div class="uploader"><p>{{ dataHighlightContentVideo.channel_name }}</p></div>
                        <div class="date-uploaded"><p>{{ dataHighlightContentVideo.date }}</p></div>
                    </div>
                </div>
            </div>
            <div class="grid-container">
                <div class="card-video" v-for="(dataContentVideo) in dataContentVideo" :key="dataContentVideo.id">
                    <iframe :src="dataContentVideo.embed_video_url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <div class="box-information">
                        <div class="title"><p>{{ dataContentVideo.video_title }}</p></div>
                        <div class="uploader"><p>{{ dataContentVideo.channel_name }}</p></div>
                        <div class="date-uploaded"><p>{{ dataContentVideo.date }}</p></div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import TopHeader from "../components/Header/TopHeader.vue"
  import {onBeforeMount, ref} from 'vue'
  import { useStore } from "vuex";
  
  export default {
    name: "ContentView",
    components: {
      TopHeader
    },
    setup(){
        const store = useStore()
        let page = ref('Content')
        let allDataContentVideo = ref(null)
        let dataHighlightContentVideo = ref([])
        let dataContentVideo = ref([])

        const fetchContentVideo=async()=>{
            await store.dispatch('getValueContentVideo')
            allDataContentVideo.value = await store.getters.getAllContentVideo
            allDataContentVideo.value = allDataContentVideo.value.result
            for(let i = 0; i < allDataContentVideo.value.length; i++){
                let newDate = new Date( allDataContentVideo.value[i].date );
                let dateStr = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear()
                allDataContentVideo.value[i].date = dateStr
                if(allDataContentVideo.value[i].is_highlight == true){
                    dataHighlightContentVideo.value = allDataContentVideo.value[i]
                }else{
                    dataContentVideo.value.push(allDataContentVideo.value[i])
                }
            }
        }

        onBeforeMount(()=>{
            fetchContentVideo()
        })
  
        return{
            page,
            allDataContentVideo,
            dataHighlightContentVideo,
            dataContentVideo,
        }
      }
  };
  </script>
      
  <style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
    @import "../scss/ContentView.scss"
  </style>
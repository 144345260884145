<template>
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>

  <div class="container-blog">
    <TopHeader :activePage="page" />
    <div class="main-blog" v-if="pageNav == 'blog'">
      <div class="box-header">
        <div class="btn-newBlog" @click="pageNav = 'post'">
          <img src="../assets/newBlog.png" alt="">
          <p>Buat Blog</p>
        </div>
      </div>
      <div class="section-1">
        <div class="featured-news" v-if="isLoadingBlogHighlight == false"
          @click="pageNav = 'detail', getBlogDetailId('featured', 0)">
          <div class="box-left"><img :src="newDataHighlightBlog.blog_attachment_file_url"></div>
          <div class="box-right">
            <div class="top">
              <div class="header">
                <p>{{ newDataHighlightBlog.blog_title }}</p>
              </div>
              <div class="description">
                <p>{{ newDataHighlightBlog.blog_content_brief }}</p>
              </div>
            </div>
            <div class="box-information">
              <div class="left">
                <img :src="newDataHighlightBlog.user_picture">
                <div class="author">
                  <p>{{ newDataHighlightBlog.user_name }}</p>
                </div>
              </div>
              <div class="date">
                <p>{{ newDataHighlightBlog.blog_date }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="featured-news" v-else>
          <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="popular-news">
          <div class="box-header">
            <div class="header">
              <p>Terpopuler</p>
            </div>
          </div>
          <div class="box-popular-news" v-if="isLoadingBlogPopular == false">
            <div class="card-popular" v-for="(popularBlog, id) in allDataPopularBlog" :key="popularBlog.id"
              @click="pageNav = 'detail', getBlogDetailId('popular', id)">
              <div class="box-left"><img :src="popularBlog.blog_attachment_file_url"></div>
              <div class="box-right">
                <div class="title">
                  <p>{{ popularBlog.blog_title }}</p>
                </div>
                <div class="author">
                  <p>{{ popularBlog.user_name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="box-popular-news" v-else>
            <div class="spinner-border text-primary" role="status"></div>
          </div>
        </div>
      </div>
      <div class="section-2">
        <div class="box-header">
          <div class="nav-bar" :class="[{ active: activeCategory === 'all' }]"
            @click="activeCategory = 'all', fetchAllBlog()">
            <p>All</p>
          </div>
          <div v-for="(dataBlogCategories, id) in allDataBlogCategories" :key="dataBlogCategories.id" class="nav-bar"
            :class="[{ active: activeCategory === dataBlogCategories.name }]"
            @click="activeCategory = dataBlogCategories.name, getCategoryId(id)">
            <p>{{ dataBlogCategories.name }}</p>
          </div>
        </div>
        <div class="box-news" v-if="blogStatus == true && isLoadingBlog == false">
          <div class="card-news" v-for="(dataBlog, id) in newDataBlog" :key="dataBlog.id"
            @click="pageNav = 'detail', getBlogDetailId('blog', id)">
            <div class="card-top">
              <img :src="dataBlog.blog_attachment_file_url">
              <div class="title">
                <p>{{ dataBlog.blog_title }}</p>
              </div>
              <div class="description">
                <p>{{ dataBlog.blog_content_brief }}</p>
              </div>
            </div>
            <div class="card-bottom">
              <div class="box-information">
                <img :src="dataBlog.user_picture">
                <div class="author">
                  <p>{{ dataBlog.user_name }}</p>
                </div>
                <div class="date">
                  <p>{{ dataBlog.blog_date }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-else-if="isLoadingBlog == true">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="box-news" v-else>
          <p></p>
          <p>No blog post yet...</p>
          <p></p>
        </div>
        <div class="paginate-left" v-if="blogStatus == true && isLoadingBlog == false">
          <p>Showing {{ start_page_blog + 1 }} to {{ end_page_blog }} of {{ total_item_blog }} entries</p>
          <paginate v-model="paginate_blog" :page-count="total_page_blog" :page-range="3" :margin-pages="1"
            :click-handler="changePageBlog" :prev-text="'<'" :next-text="'>'" :container-class="'pagination'"
            :page-class="'page-item'">>
          </paginate>
        </div>
      </div>
    </div>
    <div class="main-blog" v-if="pageNav == 'detail'">
      <div class="box-blog-detail">
        <div class="return-to-blog" @click="pageNav = 'blog'">
          <img src="../assets/arrow-left.png" alt="">
          <p>Kembali ke Blog</p>
        </div>
        <div class="blog-detail">
          <div class="header">
            <div class="header-left">
              <img :src="allDataBlogDetail.user_picture" alt="">
              <div class="author">
                <p>{{ allDataBlogDetail.user_name }}</p>
              </div>
              <div class="date">
                <p>{{ allDataBlogDetail.blog_date }}</p>
              </div>
            </div>
            <div class="header-right">
              <!-- <div class="btn-more"><img src="../assets/multi-dots.png" alt=""></div> -->
            </div>
          </div>
          <div class="title">
            <p>{{ allDataBlogDetail.blog_title }}</p>
          </div>
          <img class="blog-picture" :src="allDataBlogDetail.blog_attachment_file_url" alt="">
          <div class="content" style="whiteSpace: pre-line">
            <p>{{ allDataBlogDetail.blog_content }}</p>
          </div>
        </div>
        <div class="nav-related">
          <div class="header">
            <p>Berita Terkait</p>
          </div>
        </div>
        <div class="box-card-related" v-if="relatedStatus == true && isLoadingBlogRelated == false">
          <div class="card-related" v-for="(dataRelated, id) in newDataRelatedBlog" :key="dataRelated.id"
            @click="pageNav = 'detail', getBlogDetailId('related', id)">
            <div class="card-top">
              <img :src="dataRelated.blog_attachment_file_url" alt="">
              <div class="title">
                <p>{{ dataRelated.blog_title }}</p>
              </div>
              <div class="description">
                <p>{{ dataRelated.blog_content_brief }}</p>
              </div>
            </div>
            <div class="card-bottom">
              <div class="box-information">
                <img :src="dataRelated.user_picture" alt="">
                <div class="author">
                  <p>{{ dataRelated.user_name }}</p>
                </div>
                <div class="date">
                  <p>{{ dataRelated.blog_date }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-else-if="isLoadingBlogRelated == true">
          <div class="spinner-border text-primary" role="status"></div>
        </div>
        <div v-else>
          <p>No Related Blog yet...</p>
        </div>
      </div>
    </div>
    <div class="main-blog" v-if="pageNav == 'post'">
      <div class="box-blog-detail">
        <div class="return-to-blog" @click="pageNav = 'blog'">
          <img src="../assets/arrow-left.png" alt="">
          <p>Kembali ke Blog</p>
        </div>
      </div>
      <div class="box-blog-post" v-if="isLoadingSubmit == false">
        <div class="post-title">
          <p>Form Blog</p>
        </div>
        <div class="post-form">
          <div class="form">
            <div class="title">
              <p>Blog Title</p>
            </div>
            <input type="text" name="" id="" v-model="inputBlogTitle">
          </div>
          <div class="form">
            <div class="title">
              <p>Blog Category</p>
            </div>
            <select name="" id="" v-model="idCategory">
              <option v-for="(dataBlogCategories, id) in allDataBlogCategories" :key="dataBlogCategories.id" :value="id">
                {{ dataBlogCategories.name }}</option>
            </select>
          </div>
          <div class="form">
            <div class="title">
              <p>Blog Content</p>
            </div>
            <textarea name="" id="" v-model="inputBlogContent"></textarea>
          </div>
          <div class="box-input-logo">
            <div class="title">
              <p>New Attachment (.jpg/.jpeg/.png)</p>
            </div>
            <div class="input-logo"></div>
            <input @change="addLampiranFile" id="input=pictureAdd" type="file" accept=".jpg,.jpeg,.png" />
            <label for="input=pictureAdd" class="add-logo">
              <img v-if="previewImg" :src="previewImg" />
              <div v-else class="else">
                <img src="../assets/logo-change.svg" alt="">
                <p>Add File</p>
              </div>
            </label>
            <p class="file-name">File name: {{ pictureName }}</p>
          </div>
        </div>
        <div class="post-btn">
          <div class="btn-cancel" @click="pageNav = 'blog', clearData()">
            <p>Batalkan</p>
          </div>
          <div class="btn-submit" @click="postBlog()">
            <p>Ajukan</p>
          </div>
        </div>
      </div>
      <!-- <div class="box-blog-post" v-else> -->
      <div v-else class="spinner-border text-primary" role="status"></div>
      <!-- </div> -->
    </div>
  </div>
</template>
    
<script>
import TopHeader from "../components/Header/TopHeader.vue"
import { onBeforeMount, ref } from 'vue'
import Paginate from 'vuejs-paginate-next';
import { useStore } from "vuex";
import Axios from 'axios'
import { getAuth } from 'firebase/auth'

export default {
  name: "BlogView",
  components: {
    Paginate,
    TopHeader
  },
  setup() {
    const auth = getAuth()
    const store = useStore()
    let page = ref('Blog')
    let activeCategory = ref('all')
    let pageNav = ref('blog')
    let categoryId = ref(null)
    let blogStatus = ref(false)
    let relatedStatus = ref(false)
    let previewImg = ref(null)

    let isLoadingBlogHighlight = ref(true)
    let isLoadingBlogPopular = ref(true)
    let isLoadingBlog = ref(true)
    let isLoadingBlogRelated = ref(true)
    let isLoadingSubmit = ref(false)

    let allDataBlogCategories = ref([])
    let allDataHighlightBlog = ref([])
    let allDataPopularBlog = ref([])
    let allDataBlog = ref([])
    let allDataBlogDetail = ref([])
    let allDataRelatedBlog = ref([])

    let newDataHighlightBlog = ref([])
    let newDataBlog = ref([])
    let newDataRelatedBlog = ref([])

    let popUpText = ref('')
    const popUp = (status) => {
      if (status === 'Success') {
        popUpText.value = 'Submit Blog Success'
        document.getElementById("popup").style.display = "flex";
      } else {
        popUpText.value = 'Submit Blog Failed'
        document.getElementById("popupFailed").style.display = "flex";
      }
    }
    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    }

    const getCategoryId = (id) => {
      categoryId.value = allDataBlogCategories.value[id].id
      fetchBlog()
    }

    //Post State
    let inputBlogTitle = ref(null)
    let inputBlogContent = ref(null)
    let inputBlogCategoryId = ref(null)
    let inputBlogAttachment = ref(null)
    let idCategory = ref(0)
    let pictureName = ref(null)
    const sendDataBlog = new FormData();

    const addLampiranFile = (event) => {
      sendDataBlog.delete('blog_attachment_file')
      let selectedFile = event.target.files[0];
      sendDataBlog.append("blog_attachment_file", selectedFile)
      pictureName.value = selectedFile.name
      const file = event.target.files[0];
      previewImg.value = URL.createObjectURL(file);
    }

    const postBlog = () => {
      isLoadingSubmit.value = true
      let token = localStorage.getItem('idToken')
      //get category id
      inputBlogCategoryId.value = allDataBlogCategories.value[idCategory.value].id

      console.log(inputBlogContent)

      sendDataBlog.append("blog_title", inputBlogTitle.value)
      sendDataBlog.append("blog_content", inputBlogContent.value)
      sendDataBlog.append("blog_category_id", inputBlogCategoryId.value)

      Axios.post('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs', sendDataBlog, {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }
      ).then(() => {
        popUp('Success')
        isLoadingSubmit.value = false
      }).catch(async (err) => {
        if (err.message === 'Request failed with status code 401') {
          localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
          location.reload();
        } else {
          popUp('Failed')
          isLoadingSubmit.value = false
        }
      })
      clearData()
    }
    const clearData = () => {
      window.scrollTo({ top: 0, behavior: "instant" })
      previewImg.value = null
      inputBlogTitle.value = ''
      inputBlogContent.value = ''
      inputBlogCategoryId.value = ''
      document.getElementById("input=pictureAdd").value = "";
      pictureName.value = ''
    }

    //Detail state
    const getBlogDetailId = (source, id) => {
      let token = localStorage.getItem('idToken')
      if (source == 'featured') {
        window.scrollTo({ top: 0, behavior: "instant" })
        allDataBlogDetail.value = newDataHighlightBlog.value
      } else if (source == 'popular') {
        window.scrollTo({ top: 0, behavior: "instant" })
        allDataBlogDetail.value = allDataPopularBlog.value[id]
      } else if (source == 'blog') {
        window.scrollTo({ top: 0, behavior: "instant" })
        allDataBlogDetail.value = newDataBlog.value[id]
      } else if (source == 'related') {
        window.scrollTo({ top: 0, behavior: "instant" })
        allDataBlogDetail.value = newDataRelatedBlog.value[id]
        scroll(0, 0)
      }

      console.log(allDataBlogDetail)

      fetchRelatedBlog(allDataBlogDetail.value.blog_category_id, allDataBlogDetail.value.id)
      Axios.post('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/tracking', {
        "blog_id": allDataBlogDetail.value.id,
      }, {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }
      ).then(() => {
      }).catch(async (err) => {
        if (err.message === 'Request failed with status code 401') {
          localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
          location.reload();
        }
      })
    }

    //Fetch
    const fetchBlogCategories = async () => {
      await store.dispatch('getValueBlogCategories')
      allDataBlogCategories.value = await store.getters.getAllBlogCategories
      allDataBlogCategories.value = allDataBlogCategories.value.result
    }

    const fetchHighlightBlog = async () => {
      isLoadingBlogHighlight.value = true
      await store.dispatch('getValueHighlightBlog')
      allDataHighlightBlog.value = await store.getters.getAllHighlightBlog
      allDataHighlightBlog.value = allDataHighlightBlog.value.result[0]
      let blogContent = allDataHighlightBlog.value.blog_content
      if (pageNav.value == 'blog' && allDataHighlightBlog.value.blog_content.length > 430) {
        blogContent = allDataHighlightBlog.value.blog_content.substring(0, 430) + " ...";
      }

      let newDate = new Date(allDataHighlightBlog.value.blog_date);
      let dateStr = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear()
      newDataHighlightBlog.value.push({
        blog_content_brief: blogContent,
        blog_attachment_file_url: allDataHighlightBlog.value.blog_attachment_file_url,
        blog_title: allDataHighlightBlog.value.blog_title,
        blog_content: allDataHighlightBlog.value.blog_content,
        user_picture: allDataHighlightBlog.value.user_picture,
        user_name: allDataHighlightBlog.value.user_name,
        blog_date: dateStr,
        id: allDataHighlightBlog.value.id,
        blog_category_id: allDataHighlightBlog.value.blog_category_id,
      })
      newDataHighlightBlog.value = newDataHighlightBlog.value[0]
      console.log(newDataHighlightBlog)
      isLoadingBlogHighlight.value = false
    }

    const fetchPopularBlog = async () => {
      isLoadingBlogPopular.value = true
      await store.dispatch('getValuePopularBlog')
      allDataPopularBlog.value = await store.getters.getAllPopularBlog
      allDataPopularBlog.value = allDataPopularBlog.value.result.blogs
      isLoadingBlogPopular.value = false
    }

    let start_page_blog = ref(0)
    let end_page_blog = ref(6)
    let total_item_blog = ref(0)
    let paginate_blog = ref(1)
    let total_page_blog = ref(10)

    const changePageBlog = (pageNum) => {
      let new_end = pageNum * 6
      let new_start = new_end - 6
      start_page_blog.value = new_start
      end_page_blog.value = new_end

      newDataBlog.value = []
      for (let i = 0; i < allDataBlog.value.length; i++) {
        if (i >= start_page_blog.value && i < end_page_blog.value) {
          let newDate = new Date(allDataBlog.value[i].blog_date);
          let dateStr = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear()
          let blogContent = allDataBlog.value[i].blog_content
          if (pageNav.value == 'blog' && allDataBlog.value[i].blog_content.length > 430) {
            blogContent = allDataBlog.value[i].blog_content.substring(0, 430) + " ...";
          }
          newDataBlog.value.push({
            id: allDataBlog.value[i].id,
            blog_attachment_file_url: allDataBlog.value[i].blog_attachment_file_url,
            blog_title: allDataBlog.value[i].blog_title,
            blog_content_brief: blogContent,
            blog_content: allDataBlog.value[i].blog_content,
            user_picture: allDataBlog.value[i].user_picture,
            user_name: allDataBlog.value[i].user_name,
            blog_date: dateStr,
            blog_category_id: allDataBlog.value[i].blog_category_id,
          })
        }
      }
    }

    const fetchAllBlog = () => {
      isLoadingBlog.value = true
      let token = localStorage.getItem('idToken')

      Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs', {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }
      ).then((res) => {
        allDataBlog.value = res.data.result.blogs

        if (allDataBlog.value.length == 0) {
          blogStatus.value = false
        } else {
          blogStatus.value = true
        }
        total_item_blog.value = allDataBlog.value.length
        total_page_blog.value = Math.ceil((allDataBlog.value.length / 6))
        changePageBlog(1)
        isLoadingBlog.value = false
      }).catch(async (err) => {
        if (err.message === 'Request failed with status code 401') {
          localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
          location.reload();
        } else {
          console.log(err.message)
        }
      })
    }

    const fetchBlog = () => {
      isLoadingBlog.value = true
      let token = localStorage.getItem('idToken')

      Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs?blog_category_id=' + categoryId.value, {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }
      ).then((res) => {

        allDataBlog.value = res.data.result.blogs
        if (allDataBlog.value.length == 0) {
          blogStatus.value = false
        } else {
          blogStatus.value = true
        }
        total_item_blog.value = allDataBlog.value.length
        total_page_blog.value = Math.ceil((allDataBlog.value.length / 6))
        changePageBlog(1)
        isLoadingBlog.value = false
      }).catch(async (err) => {
        if (err.message === 'Request failed with status code 401') {
          localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
          location.reload();
        } else {
          console.log(err.message)
        }
      })
    }

    const fetchRelatedBlog = (categoryId, exceptId) => {
      isLoadingBlogRelated.value = true
      let token = localStorage.getItem('idToken')
      Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/related?page=1&limit=3&blog_category_id=' + categoryId + '&except_blog_id=' + exceptId, {
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }
      ).then((res) => {

        allDataRelatedBlog.value = res.data.result.blogs

        newDataRelatedBlog.value = []
        for (let i = 0; i < allDataRelatedBlog.value.length; i++) {
          let newDate = new Date(allDataRelatedBlog.value[i].blog_date);
          let dateStr = newDate.getDate() + "/" + (newDate.getMonth() + 1) + "/" + newDate.getFullYear()
          let blogContent = allDataRelatedBlog.value[i].blog_content
          if (allDataRelatedBlog.value[i].blog_content.length > 430) {
            blogContent = allDataRelatedBlog.value[i].blog_content.substring(0, 270) + " ...";
          }
          newDataRelatedBlog.value.push({
            id: allDataRelatedBlog.value[i].id,
            blog_attachment_file_url: allDataRelatedBlog.value[i].blog_attachment_file_url,
            blog_title: allDataRelatedBlog.value[i].blog_title,
            blog_content_brief: blogContent,
            blog_content: allDataRelatedBlog.value[i].blog_content,
            user_picture: allDataRelatedBlog.value[i].user_picture,
            user_name: allDataRelatedBlog.value[i].user_name,
            blog_date: dateStr,
            blog_category_id: allDataRelatedBlog.value[i].blog_category_id,
          })
        }
        if (newDataRelatedBlog.value.length == 0) {
          relatedStatus.value = false
        } else {
          relatedStatus.value = true
        }
        isLoadingBlogRelated.value = false
      }).catch(async (err) => {
        if (err.message === 'Request failed with status code 401') {
          localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
          location.reload();
        } else {
          console.log(err.message)
        }
      })
    }

    onBeforeMount(() => {
      window.scrollTo({ top: 0, behavior: "instant" })
      fetchBlogCategories()
      fetchHighlightBlog()
      fetchPopularBlog()
      fetchAllBlog()
    })

    return {
      page,
      activeCategory,
      pageNav,
      categoryId,
      blogStatus,
      relatedStatus,
      previewImg,
      getCategoryId,
      inputBlogTitle,
      inputBlogContent,
      inputBlogCategoryId,
      inputBlogAttachment,
      idCategory,
      pictureName,
      sendDataBlog,
      addLampiranFile,
      postBlog,
      clearData,
      getBlogDetailId,
      start_page_blog,
      end_page_blog,
      total_item_blog,
      paginate_blog,
      total_page_blog,
      changePageBlog,
      fetchAllBlog,
      isLoadingBlogHighlight,
      isLoadingBlogPopular,
      isLoadingBlog,
      isLoadingBlogRelated,
      isLoadingSubmit,
      allDataBlogCategories,
      allDataHighlightBlog,
      allDataPopularBlog,
      allDataBlog,
      allDataBlogDetail,
      allDataRelatedBlog,
      newDataHighlightBlog,
      newDataBlog,
      newDataRelatedBlog,
      popUpText,
      popUp,
      closepopUp,
    }
  }
};
</script>
      
<style lang="scss">@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import "../scss/BlogView.scss"</style>
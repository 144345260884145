<template>
  <div class="container-register">
    <div class="box-register">
      <div class="card-register">
        <div class="register-logo">
          <img src="../assets/logo-pendekar.png" alt="" />
        </div>
        <div class="register-header">
          <h1>Register</h1>
        </div>
        <div class="register-detail-box">
          <div class="column-100">
            <p>Name</p>
            <div
              class="box-input-register"
              :class="[
                isFullNameCorrect === true
                  ? 'correct-input'
                  : registFullName === ''
                  ? ''
                  : 'wrong-input',
              ]"
            >
              <input
                type="text"
                v-on:keyup.enter="onCreateAcc"
                class="input-100"
                v-model="registFullName"
                @keyup="onRegistFullName"
              />
              <span v-if="isFullNameCorrect === true">
                <i class="fa-solid fa-circle-check"></i>
              </span>
            </div>
            <Transition>
              <p
                class="error-notification"
                v-if="isFullNameCorrect === false && registFullName !== ''"
              >
                Please input name (Min. 3 Character)
              </p>
            </Transition>
          </div>
          <div class="column-100">
            <p>Email</p>
            <div
              class="box-input-register"
              :class="[
                isEmailCorrect === true
                  ? 'correct-input'
                  : registEmail === ''
                  ? ''
                  : 'wrong-input',
              ]"
            >
              <input
                type="email"
                v-model="registEmail"
                @keyup="onRegistEmail"
              />
              <span v-if="isEmailCorrect === true">
                <i class="fa-solid fa-circle-check"></i>
              </span>
            </div>
            <Transition>
              <p
                class="error-notification"
                v-if="isEmailCorrect === false && registEmail !== ''"
              >
                Contoh:email@millealab.com
              </p>
            </Transition>
          </div>
          <div class="column-100">
            <p>Phone Number</p>
            <div class="box-input-register">
              <div
                class="phone"
                :class="[
                  isPhoneCorrect === true
                    ? 'correct-input'
                    : registPhone === ''
                    ? ''
                    : 'wrong-input',
                ]"
              >
                <select name="" id="" disabled="disabled">
                  <option value="+62">+62</option>
                </select>
                <input
                  type="text"
                  v-model="registPhone"
                  onkeyup="this.value=this.value.replace(/[^\d]/,'')"
                  @keyup="onRegistPhone"
                />
                <span v-if="isPhoneCorrect === true">
                  <i class="fa-solid fa-circle-check"></i>
                </span>
              </div>
            </div>
            <Transition>
              <p
                class="error-notification"
                v-if="isPhoneCorrect === false && registPhone !== ''"
              >
                Contoh:+6212345678910
              </p>
            </Transition>
          </div>
          <div class="column-100">
            <p>Password</p>
            <div
              class="box-input-register"
              :class="[
                isPasswordCorrect === true
                  ? 'correct-input'
                  : registPassword === ''
                  ? ''
                  : 'wrong-input',
              ]"
            >
              <input
                :type="typePassword"
                v-model="registPassword"
                @keyup="onRegistPassword"
              />
              <span v-if="isSeenPassword === true" v-on:click="onChangeSeen">
                <i class="fa-solid fa-eye-slash icon-eyes"></i>
              </span>
              <span v-else v-on:click="onChangeSeen">
                <i class="fa-solid fa-eye icon-eyes"></i>
              </span>
            </div>
            <Transition>
              <p
                class="error-notification"
                v-if="isPasswordCorrect === false && registPassword !== ''"
              >
                Minimal 8 character + 1 Capital + 1 Special Character + 1 Number
              </p>
            </Transition>
          </div>
          <div class="column-100">
            <p>Confirm Password</p>
            <div
              class="box-input-register"
              :class="[
                isCheckPasswordCorrect === true
                  ? 'correct-input'
                  : registCheckPassword === ''
                  ? ''
                  : 'wrong-input',
              ]"
            >
              <input
                :type="reTypePassword"
                v-model="registCheckPassword"
                @keyup="onRegistPasswordCheck"
              />
              <span
                v-if="isSeenRePassword === true"
                v-on:click="onChangeReSeen"
              >
                <i class="fa-solid fa-eye-slash icon-eyes"></i>
              </span>
              <span v-else v-on:click="onChangeReSeen">
                <i class="fa-solid fa-eye icon-eyes"></i>
              </span>
            </div>
            <Transition>
              <p
                class="error-notification"
                v-if="
                  isCheckPasswordCorrect === false && registCheckPassword !== ''
                "
              >
                Password Berbeda
              </p>
            </Transition>
          </div>
        </div>
        <!-- <div class="register-checklist-box">
                    <div class="checklist">
                        <input type="checkbox" class="checkbox">
                        <p>Yes, I want to receive email promotion from Millealab</p>
                    </div>
                </div> -->
        <div class="btn-register-box">
          <div
            class="btn-register"
            v-if="allDataIsReady"
            @click="onCreateAcc()"
          >
            <p>REGISTER</p>
          </div>
          <div class="btn-failed" v-else>
            <p>REGISTER</p>
          </div>
        </div>
        <!-- <div class="box-register-with">
                    <div class="register-with">
                        <p>Or register with</p>
                    </div>
                </div> -->
        <div class="box-other-register">
          <!-- <div class="other-register">
                        <div class="btn-other-register" @click="loginWithGoogle">
                            <img src="../assets/google.svg" alt="">
                        </div>
                    </div> -->
          <div class="register-to-login">
            <p>Already have an account?</p>
            <a href="/login">Login</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useLoading } from "vue-loading-overlay";
import Axios from "axios";
import {
  signInWithEmailAndPassword,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc, getDoc, Timestamp } from "firebase/firestore";
import { useStore } from "vuex";
import { createToast } from "mosha-vue-toastify";
import { db } from "../../firebase-config";
import { auth, provider } from "../../firebase-config";
export default {
  name: "RegisterView",
  components: {},
  setup() {
    const Loading = useLoading();
    const store = useStore();
    let allDataIsReady = ref(false);
    let typePassword = ref("password");
    let reTypePassword = ref("password");
    let registFullName = ref("");
    let registEmail = ref("");
    let registPassword = ref("");
    let registCheckPassword = ref("");
    let registPhone = ref("");

    let isFullNameCorrect = ref(false);
    let isEmailCorrect = ref(false);
    let isPasswordCorrect = ref(false);
    let isCheckPasswordCorrect = ref(false);
    let isPhoneCorrect = ref(false);
    let isSeenPassword = ref(false);
    let isSeenRePassword = ref(false);

    let userIdValue = ref();
    let userEmailValue = ref();
    let newUserCheck = ref(false);
    let registerAsPendekarCheck = ref();

    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    function validatePassword(password) {
      const re =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#+])[A-Za-z\d@$!%*?&#+]{8,}$/;
      return re.test(String(password));
    }

    const onChangeSeen = () => {
      isSeenPassword.value = !isSeenPassword.value;

      if (typePassword.value === "text") {
        isSeenPassword.value = true;
        typePassword.value = "password";
      } else {
        isSeenPassword.value = false;
        typePassword.value = "text";
      }
    };
    const onChangeReSeen = () => {
      isSeenRePassword.value = !isSeenRePassword.value;

      if (reTypePassword.value === "text") {
        isSeenRePassword.value = true;
        reTypePassword.value = "password";
      } else {
        isSeenRePassword.value = false;
        reTypePassword.value = "text";
      }
    };
    const checkAllData = () => {
      if (
        isFullNameCorrect.value &&
        isEmailCorrect.value &&
        isPasswordCorrect.value &&
        isCheckPasswordCorrect.value &&
        isPhoneCorrect.value
      ) {
        allDataIsReady.value = true;
      } else {
        allDataIsReady.value = false;
      }
      // console.log(isFullNameCorrect.value,isEmailCorrect.value,isPasswordCorrect.value,isCheckPasswordCorrect.value, isPhoneCorrect.value)
    };
    const onRegistFullName = () => {
      if (registFullName.value.length >= 3) {
        isFullNameCorrect.value = true;
        checkAllData();
      } else {
        isFullNameCorrect.value = false;
      }
    };
    const onRegistEmail = () => {
      var checking_email = validateEmail(registEmail.value);
      if (checking_email) {
        isEmailCorrect.value = true;
        checkAllData();
      } else {
        isEmailCorrect.value = false;
      }
    };
    const onRegistPassword = () => {
      var checking_password = validatePassword(registPassword.value);
      if (checking_password) {
        // console.log(checking_password)
        isPasswordCorrect.value = true;
        checkAllData();
      } else {
        isPasswordCorrect.value = false;
      }
    };
    const onRegistPasswordCheck = () => {
      // console.log(registCheckPassword,registPassword)
      if (registCheckPassword.value == registPassword.value) {
        isCheckPasswordCorrect.value = true;
        checkAllData();
      } else {
        isCheckPasswordCorrect.value = false;
      }
    };
    const onRegistPhone = () => {
      if (registPhone.value.length > 0) {
        // console.log(registPhone.value)
        isPhoneCorrect.value = true;
        checkAllData();
        let split_number = registPhone.value.split("");
        let number_62 = split_number[0] + split_number[1];
        let number_plus62 = split_number[0] + split_number[1] + split_number[2];
        let number_628 = split_number[0] + split_number[1] + split_number[2];
        // console.log(split_number)
        // console.log(number_62)
        if (number_plus62 === "+62") {
          split_number.splice(0, 3);
          registPhone.value = split_number.join("");
          // console.log('masuk ke else if +62')
        } else if (number_62 === "62") {
          // console.log(split_number)
          // console.log('masuk ke else if 62')
          split_number.splice(0, 2);
          // console.log(split_number)
          registPhone.value = split_number.join("");
        } else if (number_628 === "628") {
          split_number.splice(0, 2);
          registPhone.value = split_number.join("");
          // console.log('masuk ke else if 628')
        } else if (split_number[0] === "0") {
          split_number.splice(0, 1);
          registPhone.value = split_number.join("");
          // console.log('masuk ke else if 0')
        } else {
          // console.log('masuk ke else')
        }
      } else {
        isPhoneCorrect.value = false;
      }
    };
    const onCreateAcc = async () => {
      // console.log(registFullName.value,registEmail.value, registPassword.value, registCheckPassword.value, registPhone.value)
      if (
        isFullNameCorrect.value &&
        isEmailCorrect.value &&
        isPasswordCorrect.value &&
        isCheckPasswordCorrect.value &&
        isPhoneCorrect.value
      ) {
        // console.log('register berhasil')
        const loader = Loading.show({
          // Optional parameters
        });

        var data = JSON.stringify({
          name: registFullName.value,
          email: registEmail.value,
          phone: `+62${registPhone.value}`,
          password: registPassword.value,
          tnc: true,
        });
        // console.log(data)
        var config = {
          method: "post",
          url: "https://us-central1-millea-lab.cloudfunctions.net/web/api/createNewUser",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        Axios(config)
          .then(async function (response) {
            // console.log(response)
            console.log("masuk then");
            if (response.status === 200) {
              // register berhasil status 200
              const auth = getAuth();
              await signInWithEmailAndPassword(
                auth,
                registEmail.value,
                registPassword.value
              ).then(async (result) => {
                // console.log(result)
                let collectionID = "users";
                let docRef = doc(db, collectionID, result.user.uid);
                setDoc(docRef, { profile_number: 2 }, { merge: true });
                // createToast({
                //      title: 'Success',
                //      description: 'Register Berhasil'
                //      },
                //      {
                //      timeout: 2000,
                //      type: 'success',
                //      transition: 'bounce',
                //      position:'top-center'
                //  })

                userEmailValue.value = registEmail.value;
                userIdValue.value = result.user.uid;
                newUserCheck.value = true;
                store.dispatch("getValueLoginWithGoogle", result.user.uid);
                JSON.stringify(
                  localStorage.setItem("idStorage", result.user.uid)
                );
              });
            } else {
              // ini error status selain 200
            }
            // eslint-disable-next-line no-unused-vars
          })
          .catch(async (err) => {
            console.log("masuk catch" + err);

            await Axios.get(
              "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/by-email?email=" +
                registEmail.value
            )
              .then((res) => {
                console.log(res);
                let data = res.data.result;
                userEmailValue.value = registEmail.value;
                userIdValue.value = data.id;
              })
              .catch((err) => {
                console.log(err);
              });

            newUserCheck.value = false;
          })
          .finally(async () => {
            // console.log('masuk finally')
            // console.log(userEmailValue.value, userIdValue.value, newUserCheck.value)
            await Axios.get(
              "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/pendekar-vr-registration/check?email=" +
                userEmailValue.value
            )
              .then((res) => {
                // console.log(res)
                let data = res.data.result;
                registerAsPendekarCheck.value =
                  data.pendekar_vr_registration_approval_status;
              })
              .catch((err) => {
                console.log(err);
              });

            console.log(
              "test",
              userEmailValue.value,
              userIdValue.value,
              registerAsPendekarCheck.value
            );

            if (
              newUserCheck.value == true ||
              (newUserCheck.value == false &&
                registerAsPendekarCheck.value == "NONE")
            ) {
              //hit PendekarVRRegistration
              await Axios.post(
                "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/pendekar-vr-registration",
                {
                  user_email: userEmailValue.value,
                  user_uid: userIdValue.value,
                }
              )
                .then((res) => {
                  console.log(res);
                  createToast(
                    {
                      title: "Registrasi Berhasil",
                      description:
                        "Harap tunggu admin untuk mengupdate status pendekar anda",
                    },
                    {
                      timeout: 10000,
                      type: "success",
                      transition: "bounce",
                      position: "top-center",
                    }
                  );
                })
                .catch((err) => {
                  console.log(err);
                });
            } else if (
              newUserCheck.value == false &&
              registerAsPendekarCheck.value == "APPROVED"
            ) {
              createToast(
                {
                  title: "Terdaftar Sebagai Pendekar",
                  description:
                    "Akun dengan data diri tersebut sudah terregistrasi sebagai pendekar. Silakan login di web sekarang.",
                },
                {
                  timeout: 10000,
                  type: "success",
                  transition: "bounce",
                  position: "top-center",
                }
              );
            } else if (
              newUserCheck.value == false &&
              registerAsPendekarCheck.value == "PENDING"
            ) {
              createToast(
                {
                  title: "Status Pendaftaran Pendekar Pending",
                  description:
                    "Harap tunggu admin untuk mengupdate status pendekar anda",
                },
                {
                  timeout: 10000,
                  type: "danger",
                  transition: "bounce",
                  position: "top-center",
                }
              );
            } else {
              createToast(
                {
                  title: "Status Pendaftaran Pendekar Rejected",
                  description:
                    "Maaf pendaftaran anda direject, harap hubungi admin untuk keterangan lebih lanjut",
                },
                {
                  timeout: 10000,
                  type: "danger",
                  transition: "bounce",
                  position: "top-center",
                }
              );
            }
          });
        loader.hide();
      } else {
        allDataIsReady.value = false;
        checkAllData();
        // console.log('ada data yang salaaah')
      }
      localStorage.removeItem("idStorage");
      localStorage.removeItem("idToken");
    };

    const loginWithGoogle = async () => {
      // const googleUser = await  $gAuth.signIn()
      // console.log('google user', googleUser)

      const loader = Loading.show({
        // Optional parameters
      });
      try {
        // sign in google with pop up
        let result = await signInWithPopup(auth, provider);
        // console.log(result,' sign in with popup')
        let user = result.user;
        // console.log(user.uid)
        // console.log(user)

        // define user data
        let collectionID = "users";
        let docRef = doc(db, collectionID, user.uid);
        // console.log(docRef, 'docRef')

        // get user data
        let userDocSnap = await getDoc(docRef);
        // console.log(userDocSnap, 'docSnap')
        // console.log(userDocSnap.data())

        // if user does not exists in firestore, create default new user
        if (!userDocSnap.exists()) {
          await setDoc(
            docRef,
            {
              id: user.uid,
              name: user.displayName,
              email: user.email,
              phone: user.phoneNumber,
              created_at: Timestamp.fromDate(
                new Date(user.metadata.creationTime)
              ),
              last_signed_in: Timestamp.fromDate(
                new Date(user.metadata.lastSignInTime)
              ),
              is_admin: false,
              profile_picture: user.photoURL,
              userDetails: [],
            },
            { merge: true }
          );
          loader.hide();
        }
        // else, update the last signed in data
        else {
          let userDetails = [];
          // let profile_number = 0
          if (userDocSnap.data().userDetails) {
            userDetails = userDocSnap.data().userDetails;
          }
          // if(userDocSnap.data().profileNumber === undefined){
          //     profile_number = 2
          // }else {
          //     profile_number = userDocSnap.data().profile_number
          // }
          await setDoc(
            docRef,
            {
              last_signed_in: Timestamp.fromDate(
                new Date(user.metadata.lastSignInTime)
              ),
              userDetails: userDetails,
              // profile_number : profile_number
            },
            { merge: true }
          );
          loader.hide();
        }
        store.dispatch("getValueLoginWithGoogle", user.uid);
        JSON.stringify(localStorage.setItem("idStorage", user.uid));
        window.location.href = "/";
        loader.hide();
      } catch (err) {
        // console.log(err);
        // console.log(err.message)
        loader.hide();
      }
    };
    return {
      allDataIsReady,
      typePassword,
      reTypePassword,
      registFullName,
      registEmail,
      registPassword,
      registCheckPassword,
      registPhone,

      isFullNameCorrect,
      isEmailCorrect,
      isPasswordCorrect,
      isCheckPasswordCorrect,
      isPhoneCorrect,
      isSeenPassword,
      isSeenRePassword,

      userIdValue,
      userEmailValue,
      newUserCheck,
      registerAsPendekarCheck,

      onChangeSeen,
      onChangeReSeen,
      onRegistFullName,
      onRegistEmail,
      onRegistPassword,
      onRegistPasswordCheck,
      onRegistPhone,
      checkAllData,
      onCreateAcc,
      loginWithGoogle,
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import "../scss/Register.scss";
</style>

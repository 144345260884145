import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/DashboardView.vue"
import TaskView from "../views/TaskView.vue"
import NotificationView from "../views/NotificationView.vue"
import EventView from "../views/EventView.vue"
import BlogView from "../views/BlogView.vue"
import LoginView from "../views/LoginView.vue"
import RegisterView from "../views/RegisterView.vue"
import ContentView from "../views/ContentView.vue"
import store from '@/store/index'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const checkUserLogin=async(to,from,next)=>{
    await store.dispatch('getValueLoading',{value:true})
    const auth = getAuth()
    onAuthStateChanged(auth, async(user)=>{
      if(user){
        // console.log(user)
        JSON.stringify(localStorage.setItem('idStorage',user.uid))
        next()
        await store.dispatch('getValueLoading',{value:false}) // stop loading page
      }else{
        localStorage.removeItem('idStorage')
        localStorage.removeItem('idToken')
        await store.dispatch('getValueLoading',{value:false}) // stop loading
        await store.dispatch('getValueIsLogin',{value:false}) 
        window.location.href="/login"
      }
    })
  }
  
const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: DashboardView,
        beforeEnter(to,from,next){
            checkUserLogin(to,from,next)
          }
    },
    {
        path: "/task",
        name: "Task",
        component: TaskView,
        beforeEnter(to,from,next){
            checkUserLogin(to,from,next)
          }
    },
    {
        path: "/notification",
        name: "Notification",
        component: NotificationView,
        beforeEnter(to,from,next){
            checkUserLogin(to,from,next)
          }
    },
    {
        path: "/event",
        name: "Event",
        component: EventView,
        beforeEnter(to,from,next){
            checkUserLogin(to,from,next)
          }
    },
    {
        path: "/blog",
        name: "Blog",
        component: BlogView,
        beforeEnter(to,from,next){
            checkUserLogin(to,from,next)
          }
    },
    {
      path: "/content",
      name: "Content",
      component: ContentView,
      beforeEnter(to,from,next){
          checkUserLogin(to,from,next)
        }
  },
    {
        path: "/login",
        name: "Login",
        component: LoginView,
    },
    {
        path: "/register",
        name: "Register",
        component: RegisterView,
    },
]

export const router = createRouter({
    history: createWebHistory(),
    routes
  });
import Axios from 'axios'
import {doc,getDoc} from 'firebase/firestore'
import {db} from '../../firebase-config'
import { getAuth } from 'firebase/auth'

const auth = getAuth()

const state = {
    isLoading:false,
    isLogin:false,
    uidCustomer:undefined,
}

const mutations = {
    setValueLoading(state,{name,value}){
        state[name] = value
    },
    setValueLogin(state,{name,value}){
        state[name] = value
    },
    setValueUserData(state,{name,value}){
        state[name] = value
    },
    setValueUserProfile(state,{name,value}){
        state[name] = value
    },
    setValueTopPendekar(state,{name,value}){
        state[name] = value
    },
    setValueTaskHistory(state,{name,value}){
        state[name] = value
    },
    setValueTaskCategories(state,{name,value}){
        state[name] = value
    },
    setValueNotification(state,{name,value}){
        state[name] = value
    },
    setValueAvailableEvents(state,{name,value}){
        state[name] = value
    },
    setValueJoinedEvents(state,{name,value}){
        state[name] = value
    },
    setValueBlogCategories(state,{name,value}){
        state[name] = value
    },
    setValueHighlightBlog(state,{name,value}){
        state[name] = value
    },
    setValuePopularBlog(state,{name,value}){
        state[name] = value
    },
    setValueContentVideo(state,{name,value}){
        state[name] = value
    },
}

const actions = {
    getValueLoginWithGoogle:({commit},valueParams)=>{
        commit('setValueLogin',{name:'isLogin',value:true})
        commit('setValueLogin',{name:'user',value:valueParams})
    },
    getValueLogin:async({commit})=>{
        let idStorage  = localStorage.getItem('idStorage')
        commit('setValueUid',{name:'uidCustomer',value:idStorage})
        if(idStorage !== null && idStorage !== undefined){
            // berhasil login
            let collectionID = 'users'
            let docRef =  doc(db, collectionID, idStorage);
            let docSnapshot = await getDoc(docRef);
            let user = docSnapshot.data()
            if(user !== undefined){
                if(user.userDetails !== undefined){
                    let sendToFirebase = {
                        id: user.id,
                        email: user.email
                    }
                    commit('setValueLogin',{name:'user',value:sendToFirebase})
                    commit('setValueLogin',{name:'isLogin',value:true})
                }else {
                    let sendToFirebase = {
                        id: user.id,
                        email: user.email,
                    }
                    commit('setValueLogin',{name:'user',value:sendToFirebase})
                    commit('setValueLogin',{name:'isLogin',value:true})
                }
                
                return true
            }else {
                localStorage.removeItem('idStorage')     
                return false
            }
        }else {
            commit('setValueLogin',{name:'isLogin',value:false})
            return false
        }

    },
    getValueLoading:({commit}, valueParams)=>{
        commit('setValueLoading',{name:'isLoading',value:valueParams.value})
        return true
    },
    getValueIsLogin:async({commit},valueParams)=>{
        commit('setValueLogin',{name:'isLogin',value:valueParams.value})
    },
    getValueUserData:async({commit})=>{
        let idStorage  = localStorage.getItem('idStorage')
        await Axios.post('https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserData',{   
            uid:idStorage        
        }
        ).then((res)=>{
            commit('setValueUserData',{name:'allDataUserData',value:res.data})
        }).catch((err)=>{
            console.log(err)
            commit('setValueUserData',{name:'allDataUserData',value:[]})
        })
    },
    getValueUserProfile:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/profile',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueUserProfile',{name:'allDataUserProfile',value:res.data})
        }).catch((err)=>{
            console.log(err)
            commit('setValueUserProfile',{name:'allDataUserProfile',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.removeItem('idStorage')
                window.location.href="/login"
            }
        })
    },
    getValueTopPendekar:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/users/top-pendekar-vr',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueTopPendekar',{name:'allDataTopPendekar',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueTopPendekar',{name:'allDataTopPendekar',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValueTaskHistory:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/tasks/history',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueTaskHistory',{name:'allDataTaskHistory',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueTaskHistory',{name:'allDataTaskHistory',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValueTaskCategories:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/task-categories',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueTaskCategories',{name:'allDataTaskCategories',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueTaskCategories',{name:'allDataTaskCategories',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValueNotification:async({commit})=>{
        let uid = localStorage.getItem("idStorage")
        // let token = localStorage.getItem("idToken")
        // console.log(uid)
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/notifications?user_uid=' + uid
        // await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/notifications?user_uid=' + uid
        ).then((res)=>{
            // console.log(res.data)
            commit('setValueNotification',{name:'allDataNotification',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueNotification',{name:'allDataNotification',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValueAvailableEvents:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/events/pendekar-vr/available',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueAvailableEvents',{name:'allDataAvailableEvents',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueAvailableEvents',{name:'allDataAvailableEvents',value:[]})
            if(err.message === 'Request failed with status code 401'){
                // console.log('masuk 401')
                // console.log(await auth.currentUser.getIdToken(true))
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
                // localStorage.removeItem('idStorage')
                // window.location.href="/login"
            }
        })
    },
    getValueJoinedEvents:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/events/pendekar-vr/joined',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueJoinedEvents',{name:'allDataJoinedEvents',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueJoinedEvents',{name:'allDataJoinedEvents',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValueBlogCategories:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blog-categories',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueBlogCategories',{name:'allDataBlogCategories',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueBlogCategories',{name:'allDataBlogCategories',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValueHighlightBlog:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/highlighted',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValueHighlightBlog',{name:'allDataHighlightBlog',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueHighlightBlog',{name:'allDataHighlightBlog',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValuePopularBlog:async({commit})=>{
        let token = localStorage.getItem("idToken")
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/blogs/popular?page=1&limit=3',{   
            headers: {
            Authorization: 'Bearer ' + token,
        }}
        ).then((res)=>{
            commit('setValuePopularBlog',{name:'allDataPopularBlog',value:res.data})
            console.log(res.data)
        }).catch(async(err)=>{
            console.log(err)
            commit('setValuePopularBlog',{name:'allDataPopularBlog',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
    getValueContentVideo:async({commit})=>{
        await Axios.get('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/contents'
        ).then((res)=>{
            commit('setValueContentVideo',{name:'allDataContentVideo',value:res.data})
        }).catch(async(err)=>{
            console.log(err)
            commit('setValueContentVideo',{name:'allDataContentVideo',value:[]})
            if(err.message === 'Request failed with status code 401'){
                localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
                location.reload();
            }
        })
    },
}

const getters = {
    getIsLoading:(state)=>{
        return state.isLoading
    },
    getIsLogin:(state)=>{
        return state.isLogin
    },
    getValueUidCustomer:(state)=>{
        return state.uidCustomer
    },
    getAllUserData:(state)=>{
        return state.allDataUserData
    },
    getAllUserProfile:(state)=>{
        return state.allDataUserProfile
    },
    getAllTopPendekar:(state)=>{
        return state.allDataTopPendekar
    },
    getAllTaskHistory:(state)=>{
        return state.allDataTaskHistory
    },
    getAllTaskCategories:(state)=>{
        return state.allDataTaskCategories
    },
    getAllNotification:(state)=>{
        return state.allDataNotification
    },
    getAllAvailableEvents:(state)=>{
        return state.allDataAvailableEvents
    },
    getAllJoinedEvents:(state)=>{
        return state.allDataJoinedEvents
    },
    getAllBlogCategories:(state)=>{
        return state.allDataBlogCategories
    },
    getAllHighlightBlog:(state)=>{
        return state.allDataHighlightBlog
    },
    getAllPopularBlog:(state)=>{
        return state.allDataPopularBlog
    },
    getAllContentVideo:(state)=>{
        return state.allDataContentVideo
    },
}

const namespaced = false


export default{
    state,
    mutations,
    actions,
    getters,
    namespaced,
    auth,
}
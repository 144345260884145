<template>
  <!-- PopUp Submit Task-->
  <div id="popup" class="box-popup">
    <div class="popup-content">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <div id="popupFailed" class="box-popup">
    <div class="popup-failed">
      <div class="popup-left">
        <!-- <img src="../../assets/succeed-popup.svg" alt=""> -->
        <p>{{ popUpText }}</p>
      </div>
      <span class="close" @click="closepopUp()">&times;</span>
    </div>
  </div>
  <b-modal id="modalSubmitTask" title="Kirim Misi">
    <div class="box-input-text">
      <p>Misi</p>
      <input v-model="newNameInput" type="text" name="" id="" disabled />
    </div>
    <div class="box-input-text">
      <p>Catatan</p>
      <input v-model="newAdditionalInput" type="text" name="" id="" />
    </div>
    <div class="box-input-text">
      <p>Link</p>
      <input v-model="newLinkInput" type="url" name="" id="" />
    </div>
    <div class="box-input-logo">
      <div class="title">
        <p>Lampiran (.document/.video/.jpg/.jpeg/.png)</p>
      </div>
      <div class="input-logo"></div>
      <input
        @change="addLampiranFile"
        id="input=pictureAdd"
        type="file"
        accept=".jpg,.jpeg,.png,.document,.video"
      />
      <label for="input=pictureAdd" class="add-logo">
        <img v-if="previewImg" :src="previewImg" />
        <div v-else class="else">
          <img src="../assets/logo-change.svg" alt="" />
          <p>Add File</p>
        </div>
      </label>
      <p class="file-name">File name: {{ pictureName }}</p>
    </div>

    <div class="new-footer">
      <div class="btn-cancel" data-bs-dismiss="modal" @click="clearData()">
        <p>CANCEL</p>
      </div>
      <div
        class="btn-submit"
        data-bs-dismiss="modal"
        id="openPopup"
        @click="sendTask()"
      >
        <p>SUBMIT</p>
      </div>
    </div>
  </b-modal>
  <div class="container-task">
    <TopHeader :activePage="page" />
    <div class="main-task">
      <div class="section-1">
        <div class="box-nav">
          <div class="nav-left">
            <div
              class="nav-bar"
              :class="[{ active: selectedNav === 'Satu' }]"
              @click="selectedNav = 'Satu'"
            >
              <p>Satu Kali</p>
            </div>
            <div
              class="nav-bar"
              :class="[{ active: selectedNav === 'Berulang' }]"
              @click="selectedNav = 'Berulang'"
            >
              <p>Berulang</p>
            </div>
            <div
              class="nav-bar"
              :class="[{ active: selectedNav === 'History' }]"
              @click="selectedNav = 'History'"
            >
              <p>History</p>
            </div>
          </div>
          <div class="nav-right" v-if="selectedNav == 'Berulang'">
            <div class="box-dropdown">
              <ul>
                <li class="dropdown">
                  <div class="filter">
                    <p>Filter</p>
                    <img src="../assets/arrow-sort.png" alt="" />
                  </div>
                  <ul>
                    <li>
                      <div
                        class="box-submenu"
                        @click="filterFunction('poinUp')"
                      >
                        <p>Sortir poin</p>
                        <img src="../assets/arrow-up.png" alt="" />
                      </div>
                    </li>
                    <li>
                      <div
                        class="box-submenu"
                        @click="filterFunction('poinDown')"
                      >
                        <p>Sortir poin</p>
                        <img src="../assets/arrow-down.png" alt="" />
                      </div>
                    </li>
                    <li>
                      <div
                        class="box-submenu"
                        @click="filterFunction('submissionUp')"
                      >
                        <p>Submisi</p>
                        <img src="../assets/arrow-up.png" alt="" />
                      </div>
                    </li>
                    <li>
                      <div
                        class="box-submenu"
                        @click="filterFunction('submissionDown')"
                      >
                        <p>Sumbisi</p>
                        <img src="../assets/arrow-down.png" alt="" />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-right" v-if="selectedNav == 'History'">
            <div class="box-dropdown">
              <ul>
                <li class="dropdown">
                  <div class="filter">
                    <p>Filter</p>
                    <img src="../assets/arrow-sort.png" alt="" />
                  </div>
                  <ul>
                    <li>
                      <div
                        class="box-submenu"
                        @click="filterFunction('poinUp')"
                      >
                        <p>Sortir poin</p>
                        <img src="../assets/arrow-up.png" alt="" />
                      </div>
                    </li>
                    <li>
                      <div
                        class="box-submenu"
                        @click="filterFunction('poinDown')"
                      >
                        <p>Sortir poin</p>
                        <img src="../assets/arrow-down.png" alt="" />
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="box-table" v-if="selectedNav === 'Satu'">
          <table v-if="isLoadingCategory == false">
            <tr>
              <th>Daftar Misi</th>
              <th>Hadiah Poin</th>
              <th>Status</th>
              <th>Aksi</th>
            </tr>
            <tr v-for="(dataOnce, id) in dataTaskOnce" :key="dataOnce.id">
              <td>{{ dataOnce.description }}</td>
              <td>{{ dataOnce.point }}</td>
              <td v-if="dataOnce.task_submission_status == 'AVAILABLE'">
                Belum dilakukan
              </td>
              <td v-else-if="dataOnce.task_submission_status == 'PENDING'">
                Pending
              </td>
              <td v-else-if="dataOnce.task_submission_status == 'REJECTED'">
                Belum dilakukan (rejected)
              </td>
              <td v-else>Selesai</td>
              <td>
                <b-button
                  v-if="
                    dataOnce.task_submission_status == 'AVAILABLE' ||
                    dataOnce.task_submission_status == 'REJECTED'
                  "
                  v-b-modal.modalSubmitTask
                  cancel-disabled="true"
                  class="btn-submit"
                  @click="getIdOnce(id)"
                  ><p>Submit</p></b-button
                >
              </td>
            </tr>
          </table>
          <div v-else class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="box-table" v-else-if="selectedNav === 'Berulang'">
          <table v-if="isLoadingCategory == false">
            <tr>
              <th>Daftar Misi</th>
              <th>Hadiah Poin</th>
              <th>Jumlah Submisi</th>
              <th>Aksi</th>
            </tr>
            <tr
              v-for="(dataMultiple, id) in dataTaskMultiple"
              :key="dataMultiple.id"
            >
              <td>{{ dataMultiple.description }}</td>
              <td>{{ dataMultiple.point }}</td>
              <td>{{ dataMultiple.task_submission_count }}</td>
              <td>
                <b-button
                  v-b-modal.modalSubmitTask
                  cancel-disabled="true"
                  class="btn-submit"
                  @click="getIdMultiple(id)"
                  ><p>Submit</p></b-button
                >
              </td>
            </tr>
          </table>
          <div v-else class="spinner-border text-primary" role="status"></div>
        </div>
        <div class="box-table" v-else-if="selectedNav === 'History'">
          <table>
            <tr>
              <th>Daftar Misi</th>
              <th>Hadiah Poin</th>
              <th>Status</th>
              <th>Waktu Penyelesaian</th>
            </tr>
            <tr v-if="historyStatus == false">
              <td></td>
              <td>No Task History Yet...</td>
              <td></td>
              <td></td>
            </tr>
            <tr
              v-else
              v-for="dataCompleted in newDataTaskHistory"
              :key="dataCompleted.id"
            >
              <td>{{ dataCompleted.task_category }}</td>
              <td>{{ dataCompleted.point }}</td>
              <td>{{ dataCompleted.task_submission_status }}</td>
              <td>{{ dataCompleted.task_date }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import TopHeader from "../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import Axios from "axios";
import { getAuth } from "firebase/auth";

export default {
  name: "TaskView",
  components: {
    TopHeader,
  },
  setup() {
    const auth = getAuth();
    const store = useStore();
    let page = ref("task");
    let selectedNav = ref("Satu");
    let alldataTaskList = ref(null);
    let allDataTaskHistory = ref(null);
    let historyStatus = ref(null);
    let dataTaskOnce = ref([]);
    let dataTaskMultiple = ref([]);
    let previewImg = ref(null);

    let isLoadingCategory = ref(true);
    let isLoadingHistory = ref(true);

    let newNameInput = ref(null);
    let newCategoryId = ref(null);
    let newAdditionalInput = ref(null);
    let newLinkInput = ref(null);
    let newDate = ref(null);
    let pictureName = ref(null);

    let popUpText = ref("");
    const popUp = (status) => {
      if (status === "Success") {
        popUpText.value = "Submit Task Success";
        document.getElementById("popup").style.display = "flex";
      } else {
        popUpText.value = "Submit Task Failed";
        document.getElementById("popupFailed").style.display = "flex";
      }
    };
    const closepopUp = () => {
      document.getElementById("popup").style.display = "none";
      document.getElementById("popupFailed").style.display = "none";
    };

    const sendDataTask = new FormData();
    const getDate = () => {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }
      today = yyyy + "-" + mm + "-" + dd;
      newDate.value = today;
    };
    const getIdOnce = (id) => {
      newNameInput.value = dataTaskOnce.value[id].description;
      newCategoryId.value = dataTaskOnce.value[id].id;
      getDate();
    };
    const getIdMultiple = (id) => {
      newNameInput.value = dataTaskMultiple.value[id].description;
      newCategoryId.value = dataTaskMultiple.value[id].id;
      getDate();
    };
    const addLampiranFile = (event) => {
      sendDataTask.delete("image");
      let selectedFile = event.target.files[0];
      sendDataTask.append("task_attachment_file", selectedFile);
      pictureName.value = selectedFile.name;
      const file = event.target.files[0];
      previewImg.value = URL.createObjectURL(file);
    };
    const clearData = () => {
      newNameInput.value = null;
      newCategoryId.value = null;
      newAdditionalInput.value = null;
      newLinkInput.value = null;
      newDate.value = null;
      document.getElementById("input=pictureAdd").value = "";
      pictureName.value = null;
      previewImg.value = null;
    };
    const sendTask = () => {
      let token = localStorage.getItem("idToken");
      sendDataTask.append(
        "task_additional_information",
        newAdditionalInput.value
      );
      sendDataTask.append("task_category_id", newCategoryId.value);
      sendDataTask.append("task_date", newDate.value);
      sendDataTask.append("task_link", newLinkInput.value);

      Axios.post(
        "https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/tasks",
        sendDataTask,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then(() => {
          popUp("Success");
          fetchTaskCategories();
        })
        .catch(async (err) => {
          if (err.message === "Request failed with status code 401") {
            localStorage.setItem(
              "idToken",
              await auth.currentUser.getIdToken(true)
            );
            location.reload();
          } else {
            popUp("Failed");
          }
        });
      clearData();
    };

    const filterFunction = (params) => {
      if (selectedNav.value == "Berulang") {
        if (params == "poinUp") {
          dataTaskMultiple.value.sort((a, b) => b.point - a.point);
        } else if (params == "poinDown") {
          dataTaskMultiple.value.sort((a, b) => a.point - b.point);
        } else if (params == "submissionUp") {
          // task_submission_count
          dataTaskMultiple.value.sort(
            (a, b) => b.task_submission_count - a.task_submission_count
          );
        } else if (params == "submissionDown") {
          dataTaskMultiple.value.sort(
            (a, b) => a.task_submission_count - b.task_submission_count
          );
        }
      } else if (selectedNav.value == "History") {
        if (params == "poinUp") {
          newDataTaskHistory.value.sort((a, b) => b.point - a.point);
        } else if (params == "poinDown") {
          newDataTaskHistory.value.sort((a, b) => a.point - b.point);
        }
      }
    };

    const fetchTaskCategories = async () => {
      isLoadingCategory.value = true;
      await store.dispatch("getValueTaskCategories");
      alldataTaskList.value = await store.getters.getAllTaskCategories;
      alldataTaskList.value = alldataTaskList.value.result;
      dataTaskMultiple.value = [];
      dataTaskOnce.value = [];
      for (let i = 0; i < alldataTaskList.value.length; i++) {
        if (alldataTaskList.value[i].task_type == "MULTIPLE") {
          dataTaskMultiple.value.push(alldataTaskList.value[i]);
        } else if (alldataTaskList.value[i].task_type == "ONCE") {
          dataTaskOnce.value.push(alldataTaskList.value[i]);
        }
      }
      isLoadingCategory.value = false;
    };

    let alterDataTaskHistory = ref(null);
    let newDataTaskHistory = ref([]);
    const fetchTaskHistory = async () => {
      isLoadingHistory.value = true;
      await store.dispatch("getValueTaskHistory");
      allDataTaskHistory.value = await store.getters.getAllTaskHistory;
      alterDataTaskHistory.value = allDataTaskHistory.value.alternative_result
      allDataTaskHistory.value = allDataTaskHistory.value.result;

      for (let i = 0; i < alterDataTaskHistory.value.length; i++) {
        let newDate = new Date(alterDataTaskHistory.value[i].task_date);
        let dateStr =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();
        // newDataTaskHistory.value[i] = [{
        //   task_category: allDataTaskHistory.value[i].task_category,
        //   point: allDataTaskHistory.value[i].point,
        //   task_date: dateStr
        // }]
        newDataTaskHistory.value.push({
          task_submission_status : alterDataTaskHistory.value[i].task_submission_status,
          task_category: alterDataTaskHistory.value[i].task_category,
          point: alterDataTaskHistory.value[i].point,
          task_date: dateStr,
        });
      }

      if (allDataTaskHistory.value.length == 0) {
        historyStatus.value = false;
      } else {
        historyStatus.value = true;
      }
      isLoadingHistory.value - false;
    };

    onBeforeMount(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
      fetchTaskCategories();
      fetchTaskHistory();
    });

    return {
      page,
      selectedNav,
      alldataTaskList,
      allDataTaskHistory,
      newDataTaskHistory,
      alterDataTaskHistory,
      historyStatus,
      dataTaskOnce,
      dataTaskMultiple,
      previewImg,
      isLoadingCategory,
      isLoadingHistory,
      newNameInput,
      newCategoryId,
      newAdditionalInput,
      newLinkInput,
      pictureName,
      newDate,
      sendDataTask,
      getIdOnce,
      getIdMultiple,
      addLampiranFile,
      clearData,
      sendTask,
      filterFunction,
      popUpText,
      popUp,
      closepopUp,
    };
  },
};
</script>
    
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import "../scss/TaskView.scss";
</style>
<template>
    <div class="container-notification">
      <TopHeader
        :activePage="page"
      />
      <div class="main-notification">
        <div class="notification-card">
            <div class="box-bullet"><div class="bullet"></div></div>
            <div class="details">
                <p class="title">Title</p>
                <p class="desc">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Omnis aut tempora possimus et pariatur ea sint corrupti velit error reiciendis quisquam ad unde corporis autem, saepe tenetur quod cumque minima!</p>
            </div>
            <div class="date"><p>Date</p></div>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import TopHeader from "../components/Header/TopHeader.vue"
  import {ref} from 'vue'
  
  export default {
    name: "NotificationView",
    components: {
      TopHeader
    },
    setup(){
        let page = ref('Notification')
  
        return{
            page,
        }
      }
  };
  </script>
      
  <style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
    @import "../scss/NotificationView.scss"
  </style>
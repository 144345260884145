<template>
  <div class="container-dashboard">
    <TopHeader :activePage="page" />
    <div class="main-dashboard" v-if="isDetail == false">
      <div class="box-dashboard-left">
        <div class="section-1">
          <div class="header-box">
            <div class="header-left">
              <p>Statistik Hasil Poin Harian</p>
            </div>
            <div class="header-right">
              <!-- <select id="showby">
                <option value="week">By weeks</option>
                <option value="month">By month</option>
                <option value="year">By year</option>
              </select> -->
            </div>
          </div>
          <div class="chart-box">
            <apexchart
              width="100%"
              height="330"
              type="line"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </div>
        <div class="section-2">
          <div class="header-box">
            <p>Daftar Misi</p>
          </div>
          <div class="box-table" v-if="isLoadingMisi == false">
            <table>
              <tr>
                <th>Daftar Tugas</th>
                <th>Hadiah Poin</th>
                <th>Status</th>
                <th>Waktu Penyelesaian</th>
              </tr>
              <!-- <div v-if="allDataTaskHistory.result.length == 0" class="no-data">
                <p>No Task History Yet...</p>
              </div> -->
              <tr v-if="historyStatus == false">
                <td></td>
                <td>No Task History Yet...</td>
                <td></td>
                <td></td>
              </tr>
              <tr
                v-else
                v-for="dataCompleted in newDataTaskHistory"
                :key="dataCompleted.id"
              >
                <td>{{ dataCompleted.task_category }}</td>
                <td>{{ dataCompleted.point }}</td>
                <td>{{ dataCompleted.task_submission_status }}</td>
                <td>{{ dataCompleted.task_date }}</td>
              </tr>
            </table>
          </div>
          <div v-else class="spinner-border text-primary" role="status"></div>
          <div class="box-footer">
            <router-link to="/task">
              <div class="btn-more">
                <p>Selengkapnya...</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="section-3">
          <div class="header-box">
            <p>Top 10 Ambassador MilleaLab</p>
          </div>
          <div class="box-table" v-if="isLoadingAmbassador == false">
            <table>
              <tr>
                <th>Peringkat</th>
                <th>Nama</th>
                <th>Poin</th>
                <th>Level</th>
              </tr>
              <tr
                v-for="(dataTopPendekar, id) in allDataTopPendekar"
                :key="dataTopPendekar.id"
                :class="[{ active: id == 10 || id == rank - 1 }]"
              >
                <td v-if="id == 0">
                  <span v-if="dataTopPendekar?.current_user">
                    <img src="../assets/play.png" alt="" />
                  </span>
                  <img src="../assets/rank1.png" alt="" />
                </td>
                <td v-else-if="id == 1">
                  <span v-if="dataTopPendekar?.current_user">
                    <img src="../assets/play.png" alt="" />
                  </span>
                  <img src="../assets/rank2.png" alt="" />
                </td>
                <td v-else-if="id == 2">
                  <span v-if="dataTopPendekar?.current_user">
                    <img src="../assets/play.png" alt="" />
                  </span>
                  <img src="../assets/rank3.png" alt="" />
                </td>
                <td v-else-if="id == 10 || id == rank - 1">
                  <img src="../assets/play.png" alt="" />
                  {{ rank }}
                </td>
                <td v-else>
                  <span v-if="dataTopPendekar?.current_user">
                    <img src="../assets/play.png" alt="" />
                  </span>
                  {{ id + 1 }}
                </td>
                <td>{{ dataTopPendekar.name }}</td>
                <td>{{ dataTopPendekar.points }}</td>
                <td>{{ dataTopPendekar.pendekar_tier }}</td>
              </tr>
            </table>
            <!-- <img class="active-user" src="../assets/play.png" alt=""> -->
          </div>
          <div v-else class="spinner-border text-primary" role="status"></div>
        </div>
      </div>
      <div class="box-dashboard-right">
        <div class="section-1">
          <div class="box-header">
            <p class="title">Peringkat Anda</p>
            <div class="box-peringkat" @click="isDetail = true">
              <div class="box-img">
                <img
                  v-if="pendekar_tier == 'Tidak Berperingkat'"
                  src="../assets/peringkat/1.png"
                  alt=""
                />
                <img
                  v-if="pendekar_tier == 'Pendekar VR Muda'"
                  src="../assets/peringkat/2.png"
                  alt=""
                />
                <img
                  v-if="pendekar_tier == 'Pendekar VR Utama'"
                  src="../assets/peringkat/3.png"
                  alt=""
                />
                <img
                  v-if="pendekar_tier == 'Pendekar VR Ahli'"
                  src="../assets/peringkat/4nostar.png"
                  alt=""
                />
                <img
                  v-if="pendekar_tier == 'Pendekar VR Sakti'"
                  src="../assets/peringkat/5nostar.png"
                  alt=""
                />
                <img
                  v-if="pendekar_tier == 'Pendekar VR Legenda'"
                  src="../assets/peringkat/6nostar.png"
                  alt=""
                />
              </div>
              <p class="rank">{{ rank }}</p>
            </div>
            <p>{{ pendekar_tier }}</p>
          </div>
          <div class="box-poin">
            <p class="poin">{{ poin }} poin</p>
            <b-progress
              :value="progressPercentage"
              :max="100"
              class="mb-3"
            ></b-progress>
            <p>{{ levelUpPoin }} poin lagi untuk naik level</p>
          </div>
        </div>
        <div class="section-2">
          <div class="box-header">
            <p>Acara Mendatang</p>
          </div>
          <div v-if="eventStatus == false" class="box-event">
            <router-link to="/event">
              <p class="title">No Upcoming Event Yet...</p>
            </router-link>
          </div>
          <div
            v-else
            class="box-event"
            v-for="dataEvent in allDataUpcomingEvents"
            :key="dataEvent.id"
          >
            <router-link to="/event">
              <p class="title">{{ dataEvent.event_title }}</p>
              <p>
                {{ dataEvent.event_date.replace("T", " ").split(".")[0] }}
                {{ dataEvent.event_date_timezone }}
              </p>
              <p>{{ dataEvent.event_type }}</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="main-rank" v-else>
      <div class="box-back" @click="backToDashboard()">
        <img src="../assets/arrow-left.png" alt="" />
        <p>Kembali ke dashboard</p>
      </div>
      <div class="box-rank">
        <div class="section-1">
          <p>Peringkat Saat Ini</p>
          <div class="rank-detail">
            <div class="box-detail">
              <div class="box-image">
                <img src="../assets/peringkat/1.png" alt="" />
              </div>
              <h2>Tidak Berperingkat</h2>
              <p>0 Poin</p>
            </div>
            <div class="box-detail">
              <div class="box-image">
                <img src="../assets/peringkat/2.png" alt="" />
              </div>
              <h2>Pendekar VR Muda</h2>
              <p>200 Poin</p>
            </div>
            <div class="box-detail">
              <div class="box-image">
                <img src="../assets/peringkat/3.png" alt="" />
              </div>
              <h2>Pendekar VR Utama</h2>
              <p>500 Poin</p>
            </div>
            <div class="box-detail">
              <div class="box-image">
                <img src="../assets/peringkat/4star.png" alt="" />
              </div>
              <h2>Pendekar VR Ahli</h2>
              <p>750 Poin</p>
            </div>
            <div class="box-detail">
              <div class="box-image">
                <img src="../assets/peringkat/5star.png" alt="" />
              </div>
              <h2>Pendekar VR Sakti</h2>
              <p>1000 Poin</p>
            </div>
            <div class="box-detail">
              <div class="box-image">
                <img src="../assets/peringkat/6star.png" alt="" />
              </div>
              <h2>Pendekar VR Legenda</h2>
              <p>1500 Poin</p>
            </div>
          </div>
          <b-progress
            :value="totalProgressPercentage"
            :max="100"
            class="mb-3"
          ></b-progress>
          <div class="box-bubble">
            <div class="bubble" id="myBtn">
              <p>Poinmu saat ini</p>
              <p>{{ poin }} poin</p>
            </div>
          </div>
        </div>
        <div class="section-2">
          <p>Keuntungan</p>
          <div class="nav-bar">
            <div
              class="nav-btn"
              :class="[{ active: selectedNav === 'VRMuda' }]"
              @click="selectedNav = 'VRMuda'"
            >
              <p>Pendekar VR Muda</p>
            </div>
            <div
              class="nav-btn"
              :class="[{ active: selectedNav === 'VRUtama' }]"
              @click="selectedNav = 'VRUtama'"
            >
              <p>Pendekar VR Utama</p>
            </div>
            <div
              class="nav-btn"
              :class="[{ active: selectedNav === 'VRAhli' }]"
              @click="selectedNav = 'VRAhli'"
            >
              <p>Pendekar VR Ahli</p>
            </div>
            <div
              class="nav-btn"
              :class="[{ active: selectedNav === 'VRSakti' }]"
              @click="selectedNav = 'VRSakti'"
            >
              <p>Pendekar VR Sakti</p>
            </div>
            <div
              class="nav-btn"
              :class="[{ active: selectedNav === 'VRLegenda' }]"
              @click="selectedNav = 'VRLegenda'"
            >
              <p>Pendekar VR Legenda</p>
            </div>
          </div>
          <div class="box-benefit" v-if="selectedNav === 'VRMuda'">
            <div class="box-header-muda">
              <img src="../assets/small-peringkat/1.png" alt="" />
              <p>200 poin</p>
            </div>
            <div class="box-detail">
              <p>Mendapatkan sertifikat khusus Pendekar VR Muda</p>
              <p>
                Mendapatkan badge/lencana khusus yang dapat digunakan di foto
                profil sosial media
              </p>
              <p>Mendapatkan ID/Kartu nama khusus Pendekar VR Muda</p>
              <p>
                Mendapat fitur template foto khusus Pendekar VR Muda untuk di
                website Pendekar VR
              </p>
            </div>
          </div>
          <div class="box-benefit" v-if="selectedNav === 'VRUtama'">
            <div class="box-header-utama">
              <img src="../assets/small-peringkat/2.png" alt="" />
              <p>500 poin</p>
            </div>
            <div class="box-detail">
              <p>Mendapatkan sertifikat khusus Pendekar VR Utama</p>
              <p>
                Mendapatkan badge/lencana khusus yang dapat digunakan di foto
                profil sosial media
              </p>
              <p>Mendapatkan ID/Kartu nama khusus Pendekar VR Utama</p>
              <p>
                Mendapat fitur template foto khusus Pendekar VR Utama untuk di
                website Pendekar VR
              </p>
              <p>
                Mendapatkan 1 free template khusus untuk seluruh mata pelajaran
              </p>
            </div>
          </div>
          <div class="box-benefit" v-if="selectedNav === 'VRAhli'">
            <div class="box-header-ahli">
              <img src="../assets/small-peringkat/3.png" alt="" />
              <p>750 poin</p>
            </div>
            <div class="box-detail">
              <p>Mendapatkan sertifikat khusus Pendekar VR Ahli</p>
              <p>
                Mendapatkan badge/lencana khusus yang dapat digunakan di foto
                profil sosial media
              </p>
              <p>Mendapatkan ID/Kartu nama khusus Pendekar VR Ahli</p>
              <p>
                Mendapat fitur template foto khusus Pendekar VR Ahli untuk di
                website Pendekar VR
              </p>
              <p>
                Mendapatkan 2 free template khusus untuk seluruh mata pelajaran
              </p>
            </div>
          </div>
          <div class="box-benefit" v-if="selectedNav === 'VRSakti'">
            <div class="box-header-ahli">
              <img src="../assets/small-peringkat/4.png" alt="" />
              <p>1000 poin</p>
            </div>
            <div class="box-detail">
              <p>Mendapatkan sertifikat khusus Pendekar VR Sakti</p>
              <p>
                Mendapatkan badge/lencana khusus yang dapat digunakan di foto
                profil sosial media
              </p>
              <p>Mendapatkan ID/Kartu nama khusus Pendekar VR Sakti</p>
              <p>
                Mendapat fitur template foto khusus Pendekar VR Sakti untuk di
                website Pendekar VR
              </p>
              <p>
                Mendapatkan 3 free template khusus untuk seluruh mata pelajaran
              </p>
              <p>Mendapatkan gratis merchandise resmi Pendekar VR</p>
            </div>
          </div>
          <div class="box-benefit" v-if="selectedNav === 'VRLegenda'">
            <div class="box-header-ahli">
              <img src="../assets/small-peringkat/4.png" alt="" />
              <p>1500 poin</p>
            </div>
            <div class="box-detail">
              <p>Mendapatkan sertifikat khusus Pendekar VR Legenda</p>
              <p>
                Mendapatkan badge/lencana khusus yang dapat digunakan di foto
                profil sosial media
              </p>
              <p>Mendapatkan ID/Kartu nama khusus Pendekar VR Legenda</p>
              <p>
                Mendapat fitur template foto khusus Pendekar VR Legenda untuk di
                website Pendekar VR
              </p>
              <p>
                Mendapatkan 5 free template khusus untuk seluruh mata pelajaran
              </p>
              <p>Mendapatkan gratis merchandise resmi Pendekar VR</p>
              <p>
                Mendapatkan pembuatan video profil khusus Pendekar VR Legenda
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../components/Header/TopHeader.vue";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "DashboardView",
  components: {
    TopHeader,
  },
  setup() {
    const store = useStore();
    let page = ref("dashboard");
    let isDetail = ref(false);
    let selectedNav = ref("VRMuda");
    let uid = localStorage.getItem("idStorage");

    let isLoadingMisi = ref(true);
    let isLoadingAmbassador = ref(true);

    let allDataUserData = ref(null);
    let allDataUserProfile = ref(null);
    let allDataTopPendekar = ref(null);
    let allDataTaskHistory = ref(null);
    let allDataDailyPoints = ref(null);
    let allDataUpcomingEvents = ref(null);

    let levelUpPoin = ref(0);
    let progressPercentage = ref(0);
    let totalProgressPercentage = ref(0);
    let historyStatus = ref(false);
    let eventStatus = ref(false);

    // const movBubble = document.getElementById("mov-bubble");

    // const progressBubble=()=>{
    //   movBubble.style.left = '100px'
    // }

    let seriesData = ref([]);
    let optionsData = ref([]);

    let series = ref([]);

    let options = ref({
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [],
        title: {
          text: "Tanggal",
        },
        style: {
          color: "#000000",
          fontSize: "12px",
          fontFamily: "Open Sans",
          fontWeight: "150%",
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: "Poin",
        },
        style: {
          color: "#000000",
          fontSize: "12px",
          fontFamily: "Open Sans",
          fontWeight: "150%",
        },
      },
    });

    const findDataChartDailyPoints = () => {
      for (let i = 0; i < allDataDailyPoints.value.length; i++) {
        seriesData.value.push(allDataDailyPoints.value[i].point);
        optionsData.value.push(allDataDailyPoints.value[i].date);
      }
      pushDataChartDailyPoints();
    };

    const pushDataChartDailyPoints = () => {
      (series.value = [
        {
          data: seriesData,
          name: "Total Points",
        },
      ]),
        (options.value = {
          xaxis: {
            categories: optionsData,
          },
        });
    };

    const backToDashboard = () => {
      isDetail.value = false;
      window.scrollTo({ top: 0, behavior: "instant" });
    };

    const countLevelUpPoin = () => {
      if (pendekar_tier.value === "Tidak Berperingkat") {
        levelUpPoin.value = 200 - poin.value;
        progressPercentage.value = (200 - levelUpPoin.value) / 2;
        totalProgressPercentage.value =
          (100 / 5) * (progressPercentage.value / 100);
        // progressBubble()
      } else if (pendekar_tier.value === "Pendekar VR Muda") {
        // (max - min) - (poin - min)
        // (range - missing poin)/ percentage
        levelUpPoin.value = 500 - 200 - (poin.value - 200);
        progressPercentage.value = (300 - levelUpPoin.value) / 3;
        totalProgressPercentage.value =
          (100 / 5) * (progressPercentage.value / 100) + (100 / 5) * 1;
      } else if (pendekar_tier.value === "Pendekar VR Utama") {
        levelUpPoin.value = 750 - 500 - (poin.value - 500);
        progressPercentage.value = (250 - levelUpPoin.value) / 2.5;
        totalProgressPercentage.value =
          (100 / 5) * (progressPercentage.value / 100) + (100 / 5) * 2;
      } else if (pendekar_tier.value === "Pendekar VR Ahli") {
        levelUpPoin.value = 1000 - 750 - (poin.value - 750);
        progressPercentage.value = (250 - levelUpPoin.value) / 2.5;
        totalProgressPercentage.value =
          (100 / 5) * (progressPercentage.value / 100) + (100 / 5) * 3;
      } else if (pendekar_tier.value === "Pendekar VR Sakti") {
        levelUpPoin.value = 1500 - 1000 - (poin.value - 1000);
        progressPercentage.value = (500 - levelUpPoin.value) / 5;
        totalProgressPercentage.value =
          (100 / 5) * (progressPercentage.value / 100) + (100 / 5) * 4;
      } else {
        levelUpPoin.value = 0;
        progressPercentage.value = 100;
        totalProgressPercentage.value = 100;
      }
    };

    const fetchUserProfile = async () => {
      await store.dispatch("getValueUserProfile");
      allDataUserProfile.value = await store.getters.getAllUserProfile;
      pendekar_tier.value = allDataUserProfile.value.result.pendekar_tier;
      poin.value = allDataUserProfile.value.result.points;
      allDataDailyPoints.value = allDataUserProfile.value.result.daily_points;
      // pendekar_tier.value = 'Pendekar VR Legenda'
      // poin.value = 2000
      countLevelUpPoin();
      findDataChartDailyPoints();
    };

    // pendekar
    let pendekarLength = ref(0);
    let pendekar_tier = ref(null);
    let poin = ref(0);
    let rank = ref(0);
    const fetchTopPendekar = async () => {
      isLoadingAmbassador.value = true;
      await store.dispatch("getValueTopPendekar");
      allDataTopPendekar.value = await store.getters.getAllTopPendekar;
      allDataTopPendekar.value = allDataTopPendekar.value.result;
      console.log(allDataTopPendekar.value);
      rank.value = 0;
      pendekarLength.value;
      if (allDataTopPendekar.value.length == 10) {
        pendekarLength.value = 10;
        rank.value = 0;
        for (let i = 0; i < allDataTopPendekar.value.length; i++) {
          if (
            allDataUserProfile.value.result.email ==
            allDataTopPendekar.value.email
          ) {
            // console.log(i);
            rank.value = allDataTopPendekar.value[i].rank;
          }
        }
      } else if (allDataTopPendekar.value.length == 11) {
        pendekarLength.value = 11;
        rank.value = allDataTopPendekar.value[10].rank;
      } else {
        pendekarLength.value = 11;
        rank.value = allDataTopPendekar.value[10].rank;
        allDataTopPendekar.value.splice(pendekarLength.value, 1);
      }
      // console.log(rank);
      isLoadingAmbassador.value = false;
    };

    let newDataTaskHistory = ref([]);
    const fetchTaskHistory = async () => {
      isLoadingMisi.value = true;
      await store.dispatch("getValueTaskHistory");
      allDataTaskHistory.value = await store.getters.getAllTaskHistory;
      allDataTaskHistory.value = allDataTaskHistory.value.alternative_result;

      if (allDataTaskHistory.value.length > 5) {
        allDataTaskHistory.value.length = 5;
      }

      for (let i = 0; i < allDataTaskHistory.value.length; i++) {
        let newDate = new Date(allDataTaskHistory.value[i].task_date);
        let dateStr =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();
        newDataTaskHistory.value.push({
          task_submission_status:
            allDataTaskHistory.value[i].task_submission_status,
          task_category: allDataTaskHistory.value[i].task_category,
          point: allDataTaskHistory.value[i].point,
          task_date: dateStr,
        });
      }

      if (allDataTaskHistory.value.length == 0) {
        historyStatus.value = false;
      } else {
        historyStatus.value = true;
      }
      isLoadingMisi.value = false;
    };

    const fetchUpcomingEvent = async () => {
      await store.dispatch("getValueAvailableEvents");
      allDataUpcomingEvents.value = await store.getters.getAllAvailableEvents;
      allDataUpcomingEvents.value = allDataUpcomingEvents.value.result;

      if (allDataUpcomingEvents.value.length == 0) {
        eventStatus.value = false;
      } else {
        eventStatus.value = true;
      }
    };

    onBeforeMount(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
      fetchUserProfile();
      fetchTopPendekar();
      fetchTaskHistory();
      fetchUpcomingEvent();
    });

    return {
      page,
      isDetail,
      selectedNav,
      uid,
      isLoadingMisi,
      isLoadingAmbassador,
      allDataUserData,
      allDataUserProfile,
      allDataTopPendekar,
      allDataTaskHistory,
      allDataDailyPoints,
      newDataTaskHistory,
      allDataUpcomingEvents,
      backToDashboard,
      levelUpPoin,
      progressPercentage,
      totalProgressPercentage,
      pendekar_tier,
      poin,
      rank,
      historyStatus,
      eventStatus,
      series,
      options,
      findDataChartDailyPoints,
      pushDataChartDailyPoints,
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import "../scss/DashboardView.scss";
</style>

<template>
  <div class="container-topHeader">
    <div class="container-top">
      <div class="profile-box">
        <h1 class="profile">{{ profileName }}</h1>
        <p class="profile">{{ profileSchool }}</p>
      </div>
    </div>
    <div class="container-bottom">
      <div class="navigation-bar">
        <div class="sub-page" :class="[{ active: activePage === 'dashboard' }]">
          <router-link to="/"> Dashboard </router-link>
        </div>
        <div class="sub-page" :class="[{ active: activePage === 'task' }]">
          <router-link to="/task"> Tugas </router-link>
        </div>
        <div class="sub-page" :class="[{ active: activePage === 'Blog' }]">
          <router-link to="/blog"> Blog </router-link>
        </div>
        <div class="sub-page" :class="[{ active: activePage === 'Acara' }]">
          <router-link to="/event"> Acara </router-link>
        </div>
        <div class="sub-page" :class="[{ active: activePage === 'Content' }]">
          <router-link to="/content"> Content </router-link>
        </div>
        <!-- <div class="sub-page" :class="[{ active: activePage === 'Chat' }]">Chat group</div> -->
      </div>
      <div class="notification">
        <img
          v-if="notificationOpen"
          src="../../assets/notif-active.png"
          alt=""
          @click="openNotification()"
        />
        <img
          v-else
          src="../../assets/notif.png"
          alt=""
          @click="openNotification()"
        />
        <div
          v-if="notificationOpen"
          class="invis-div"
          @click="openNotification()"
        ></div>
        <div id="notification" class="dropdown-notification">
          <div v-if="notificationStatus == false" class="notification-card">
            <div class="details">
              <p>No notification Yet...</p>
            </div>
          </div>
          <div
            v-else
            class="notification-card"
            v-for="dataNotification in newNotification"
            :key="dataNotification.id"
          >
            <div class="box-bullet"><div class="bullet"></div></div>
            <div class="details">
              <p class="title">{{ dataNotification.notification_title }}</p>
              <p class="desc">{{ dataNotification.notification_message }}</p>
            </div>
            <div class="date">
              <p>{{ dataNotification.notification_date }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="notification-alert" v-if="alertNotification == false">no alert</div> -->
        <div class="notification-alert" v-if="alertNotification == true"></div>
        <div class="btn-logout" @click="logout()">
          <p>LOG OUT</p>
        </div>
      </div>
    </div>
    <div class="container-picture">
      <img class="round" :src="profilePicture" />
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase-config";
// import { getAuth } from 'firebase/auth'

// const auth = getAuth()

export default {
  name: "topHeader",
  props: {
    activePage: String,
  },
  setup() {
    const store = useStore();
    let notificationOpen = ref(false);
    let allDataUserData = ref(null);
    let allNotification = ref(null);

    let profilePicture = ref(null);
    let profileName = ref(null);
    let profileSchool = ref(null);
    let notificationStatus = ref(null);

    const openNotification = () => {
      alertNotification.value = false;
      notificationOpen.value = !notificationOpen.value;
      document.getElementById("notification").classList.toggle("show");
    };

    const logout = () => {
      localStorage.clear();
      window.location.href = "/login";
    };

    const fetchUserData = async () => {
      await store.dispatch("getValueUserData");
      allDataUserData.value = await store.getters.getAllUserData;
      profilePicture.value = allDataUserData.value[0].profile_picture;
      profileName.value = allDataUserData.value[0].name;
      profileSchool.value = allDataUserData.value[0].company;
    };

    let newNotification = ref([]);
    const fetchNotification = async () => {
      await store.dispatch("getValueNotification");
      allNotification.value = await store.getters.getAllNotification;
      allNotification.value = allNotification.value.result;

      for (let i = 0; i < allNotification.value.length; i++) {
        let newDate = new Date(allNotification.value[i].notification_date);
        let dateStr =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();
        newNotification.value.push({
          notification_title: allNotification.value[i].notification_title,
          notification_message: allNotification.value[i].notification_message,
          notification_date: dateStr,
        });
      }

      if (allNotification.value.length == 0) {
        notificationStatus.value = false;
      } else {
        notificationStatus.value = true;
      }
    };

    let notificationUpdate = ref([]);

    // watch(async()=>notificationUpdate.value.length, function(){
    //     console.log('ada update masuk')
    //     console.log(notificationUpdate)
    // })

    const fetchNotificationUpdate = async () => {
      let idStorage = localStorage.getItem("idStorage");
      onSnapshot(
        collection(db, "web_pendekar_vr_dashboard_notifications"),
        (snap) => {
          notificationUpdate.value = [];
          snap.forEach((doc) => {
            if (doc.data().user_uid == idStorage)
              notificationUpdate.value.push(doc.data());
          });
        }
      );
      console.log(notificationUpdate);
    };

    onBeforeMount(() => {
      fetchUserData();
      fetchNotification();
      fetchNotificationUpdate();
    });

    let firstLoad = ref(true);
    let alertNotification = ref(false);

    onMounted(() => {
      watch(
        async () => notificationUpdate.value.length,
        function () {
          console.log("ada update masuk");
          console.log(notificationUpdate);
          if (firstLoad.value == true) {
            firstLoad.value = false;
          } else {
            alertNotification.value = true;
          }
        }
      );
    });

    // onUnmounted(() => clearInterval(intervalID))

    return {
      notificationOpen,
      allDataUserData,
      allNotification,
      newNotification,
      profilePicture,
      profileName,
      profileSchool,
      notificationStatus,
      openNotification,
      logout,
      notificationUpdate,
      firstLoad,
      alertNotification,
      // intervalID
    };
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import "../../scss/TopHeader.scss";
</style>
<template>
    <div class="container-event">
      <TopHeader
        :activePage="page"
      />
      <div class="main-event" v-if="eventStatus == true && isLoadingEvent == false">
        <div class="event-card" v-for="(dataEvent,id) in allDataAvailableEvents" :key="dataEvent.id">
            <div class="box-picture"><img :src="dataEvent.event_banner_file_url"></div>
            <div class="box-detail">
                <div class="title">
                    <p>{{ dataEvent.event_title }}</p>
                </div>
                <div class="details">
                    <p class="event-desc" style="whiteSpace: pre-line">{{ dataEvent.event_description }}</p>
                    <div class="place-date">
                        <div class="bold"><p>Waktu :</p></div>
                        <p>{{  dataEvent.event_date.replace('T', ' ').split('.')[0] }} {{ dataEvent.event_date_timezone }}</p>
                    </div>
                    <div class="place-date">
                        <div class="bold"><p>Tempat :</p></div>
                        <p>{{  dataEvent.event_type }}</p>
                    </div>
                    <div class="place-date">
                        <div class="bold"><p>Link/alamat :</p></div>
                        <p>{{ dataEvent.event_link }}</p>
                    </div>
                </div>
            </div>
            <div class="box-action" v-if="isLoadingUpdateAttendance == false">
                <div class="top-action"><p>bergabung ke acara sekarang?</p></div>
                <div class="bot-action">
                    <!-- <div v-if="dataEvent.logged_in_user_attendance == 'JOINED'" class="btn-disabled"><p>Terima</p></div> -->
                    <div v-if="dataEvent.logged_in_user_attendance == 'NONE' || dataEvent.logged_in_user_attendance == 'DECLINED' " class="btn-true" @click="updateEventAttendance(id, true)"><p>Terima</p></div>
                    <div v-else class="btn-false" @click="updateEventAttendance(id, false)"><p>Batalkan</p></div>
                </div>
            </div>
            <div class="box-action-loading" v-else>
                <div class="spinner-border text-primary" role="status"></div>
            </div>
        </div>
      </div>
      <div v-else-if="isLoadingEvent == true" class="main-event-loading">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
      <div v-else class="main-event">
        <div class="event-card">
          <p>No Upcoming Event Yet...</p>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import TopHeader from "../components/Header/TopHeader.vue"
  import {onBeforeMount, ref} from 'vue'
  import { useStore } from "vuex";
  import Axios from 'axios'
  import { getAuth } from 'firebase/auth'
  
  export default {
    name: "EventView",
    components: {
      TopHeader
    },
    setup(){
      const auth = getAuth()
      const store = useStore()
      let page = ref('Acara')
      let allDataAvailableEvents = ref(null)
      let allDataJoinedEvents = ref(null)

      let isLoadingEvent = ref(true)
      let isLoadingUpdateAttendance = ref(false)
      let eventId = ref(null)
      let eventStatus = ref(false)

      const geteventId=(id)=>{
        eventId.value = allDataAvailableEvents.value[id].id
      }
      
      const fetchAvailableEvents=async()=>{
        isLoadingEvent.value = true
        await store.dispatch('getValueAvailableEvents')
        allDataAvailableEvents.value = await store.getters.getAllAvailableEvents
        allDataAvailableEvents.value = allDataAvailableEvents.value.result

        console.log(allDataAvailableEvents)

        if(allDataAvailableEvents.value.length == 0){
          eventStatus.value = false
        }else{
          eventStatus.value = true
        }
        // isLoadingUpdateAttendance.value = false
        isLoadingEvent.value = false
      }

      const updateEventAttendance=(id, status)=>{
        isLoadingEvent.value = true
        geteventId(id)
        let token = localStorage.getItem('idToken')
        Axios.patch('https://us-central1-millea-lab.cloudfunctions.net/webPendekarVRDashboard/api/v1/events/' +eventId.value+ '/pendekar-vr',{
          attend:status
        },{
        headers:{
          Authorization: 'Bearer ' + token,
        }}
        ).then(()=>{
          fetchAvailableEvents()
        }).catch(async(err)=>{
        if(err.message === 'Request failed with status code 401'){
          localStorage.setItem("idToken", await auth.currentUser.getIdToken(true))
          location.reload();
        }else{
          fetchAvailableEvents()
        }
        })
      }

      onBeforeMount(()=>{
        window.scrollTo({ top:0, behavior: "instant"})
        fetchAvailableEvents()
      })
    
      return{
        page,
        allDataAvailableEvents,
        allDataJoinedEvents,
        isLoadingEvent,
        isLoadingUpdateAttendance,
        eventId,
        eventStatus,
        geteventId,
        updateEventAttendance,
      }
    }
  };
  </script>
      
  <style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
    @import "../scss/EventView.scss"
  </style>